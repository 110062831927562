import React, {
    useMemo,
    useState,
    Fragment,
    Dispatch,
    SetStateAction,
    useEffect,
  } from "react";
import { AxiosError } from "axios";
import { appointmentPatientAPI } from "../../../../utils/api/v2";

import FormControl from "@material-ui/core/FormControl";
import { Select, InputLabel, MenuItem, Button, FormControlLabel} from "@material-ui/core";
  
  interface AppointmentPatientServiceProps {
    is_fonasa: boolean;
    lab_service?: {
        id: string;
        display_name: string;
        fast_hours: number;
        lab_name: string;
        external_reference: string;
        service_code: string;
    };
    insurance: string;
    serviceId?: string;
    patientId: string;
    changeServiceInsurance: (serviceId: string, isFonasa: boolean, insurance: string) => void;
    changeAllServicesInsurance: (isFonasa: boolean, insurance: string) => void;
    mode: string;
}
  
  const EditServiceInsurance = (props: AppointmentPatientServiceProps) => {
    const [loading, setLoading] = useState(false);
    const [isFonasa, setIsFonasa] = useState(props.is_fonasa);
    const [insurance, setInsurance] = useState(props.insurance);
    const [newInsurance, setNewInsurance] = useState(props.insurance);

    const saveNewInsurance = (newInsurance) => {
      const newIsFonasa = newInsurance === "fonasa";
      setInsurance(newInsurance);
      if (props.mode === "singleTest") {
        props.changeServiceInsurance(props.serviceId, newIsFonasa, newInsurance);
      }
      if (props.mode === "allTests") {
        props.changeAllServicesInsurance(newIsFonasa, newInsurance);
      }
    };

    useEffect(() => {
      if (insurance === "fonasa") {
        setIsFonasa(true);
      } else {
        setIsFonasa(false);
      }
    }, [insurance]);

    return (
      <Fragment>
        <FormControl fullWidth style={{ padding: '1rem', marginTop: '2rem'}}>
          <InputLabel id="insurance-label" style={{ marginLeft: '1rem' }}>
            {props.mode === "singleTest" && "Modificar Previsión"}
            {props.mode === "allTests" && "Modificar Previsión De Todos Los Exámenes"}
            </InputLabel>
          <Select labelId="insurance-label" value={newInsurance} onChange={(e) => setNewInsurance(e.target.value as string)}
          style={{ margin: '0' }}>
            <MenuItem value="particular">Particular</MenuItem>
            <MenuItem value="fonasa">Fonasa</MenuItem>
            <MenuItem value="cruzblanca">CruzBlanca</MenuItem>
            <MenuItem value="nueva_masvida">Nueva Masvida</MenuItem>
          </Select>
          <Button
          color="primary"
          variant="text"
          size="small"
          disabled={newInsurance === insurance && props.mode === "singleTest"}
          onClick={() => saveNewInsurance(newInsurance)}>
            Guardar
          </Button>
        </FormControl>
      </Fragment>
    )
    };
  
  export default EditServiceInsurance;

import React from 'react';
import styled from 'styled-components';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';

const StepperWrapper = styled.div`
  width: 100%;
`

export default function MaterialStepper({ stepsLabels, completed }: { stepsLabels: Array<String>, completed: Array }) {

  return (
    <StepperWrapper>
      <Stepper alternativeLabel nonLinear activeStep={-1}>
        {stepsLabels.map((label, index) => {
          return (
            <Step key={label}>
              <StepButton completed={completed[index]}>
                {label}
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
    </StepperWrapper>
  );
}

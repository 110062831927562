import styled from "styled-components";

import Paper from "@material-ui/core/Paper";

/* Change this colors for something that fits the app */
const statusBadgeColors = {
  confirmed: "#44AF69",
  finished: "var(--primary)",
  cancelled: "#BB342F",
  other: "#E2DADB",
};

const finishedBadgeColors = {
  finished: "var(--primary)",
  notFinished: "#44AF69",
};

export const Wrapper = styled(Paper)`
  padding: 4rem;
`;

export const TitleWrapper = styled.div`
  text-align: center;
  width: 100%;

  h2 {
    margin-bottom: 20px;
  }
`;

export const SalesSource = styled.img`
  object-fit: contain;
  width: 100px;
  margin-left: 10px;
`;

export const BadgeWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
  flex-wrap: wrap;
  align-items: center;

  .MuiButtonBase-root {
    margin: 5px 10px;

    span {
      pointer-events: none;
    }
  }
`;

export const CenteredItem = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  height: 700px;
`;

export const PointerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  @media only screen and (max-width: 1160px) {
    justify-content: center;
  }
`;

export const ReceiptCell = styled.div`
  padding: 5px 10px;
  font-size: 14px;
  margin-right: 10px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
`;

export const CommentCell = styled.div`
  padding: 5px 10px;
  font-size: 14px;
  margin-right: 10px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
`;

export const DeleteCommentButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  color: red;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
`;

export const TimelineEventCell = styled.div`
  padding: 5px 10px;
  font-size: 14px;
  margin-right: 10px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
`;

export const Badge = styled.div`
  padding: 0.7rem;
  border-radius: 0.35rem;
  max-width: 10rem;
  margin: 0.5rem 0.5rem;
  ${(props: { finished?: boolean; status?: boolean; color: string }) => {
    if (props.finished) {
      return `background-color: ${finishedBadgeColors[props.color]};`;
    } else if (props.status) {
      return `background-color: ${statusBadgeColors[props.color]};`;
    } else {
      return "background-color: var(--primary);";
    }
  }}
`;

export const SectionOriginText = styled.p`
  font-size: 14px;
  margin-right: 6px;
`;

export const SubText = styled.span`
  font-size: 11px;
`;

import React, {
  useState,
  useMemo,
  useEffect,
  Fragment,
  Dispatch,
  SetStateAction,
} from "react";
import Bold from "../../../components/Typography/Bold";
import { AppointmentV2 } from "../../../utils/interfaces/Appointment";
import { AppointmentPatientV2 } from "../../../utils/interfaces/AppointmentPatient";
import moment from "moment";
import HomeIcon from "@material-ui/icons/Home";
import ApartmentIcon from "@material-ui/icons/Apartment";
import {
  SalesSource,
  BadgeWrapper,
  SectionOriginText,
  SubText,
} from "../../../assets/styles/Appointment";
import FormControl from "@material-ui/core/FormControl";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import Swal from "sweetalert2";
import appointmentService from "../../../utils/api/v1/appointmentService";
import { newSalesSources } from "../../../utils/constants/appointment";
import { AxiosError } from "axios";
import { appointmentAPI } from "../../../utils/api/v2";
import { TIMEZONE_TO_COUNTRY } from "../../../utils/data/constants";
import alliancesService from "../../../utils/api/v1/alliancesService";

interface HeaderProps {
  appointmentId: string;
  appointment: AppointmentV2;
  setAppointment: Dispatch<SetStateAction<AppointmentV2 | undefined>>;
  setError: Dispatch<SetStateAction<AxiosError | undefined>>;
  appointmentPatients: AppointmentPatientV2[];
}

const Header = ({
  appointmentId,
  appointment,
  setAppointment,
  setError,
  appointmentPatients,
}: HeaderProps) => {
  const [salesSourcesList, setSalesSourcesList] = useState<string[]>([]);

  const timezone = useMemo(() => {
    if (appointment && appointment.safe_timezone) {
      return TIMEZONE_TO_COUNTRY[appointment.safe_timezone];
    }
    return "";
  }, [appointment]);

  const mainUserFullName = useMemo(() => {
    const activeAppPatients = appointmentPatients.filter(
      (patient) => patient.active
    );
    if (activeAppPatients.length > 0) {
      const patient = activeAppPatients[0].patient;
      const firstName = patient.first_name;
      const secondName = patient.second_name;
      const lastName = patient.last_name;
      const secondLastName = patient.second_last_name;
      return `${firstName} ${secondName} ${lastName} ${secondLastName}`.replace(
        / +(?= )/g,
        ""
      );
    }
    return "";
  }, [appointmentPatients]);

  const getSalesSources = async (): Promise<void> => {
    try {
      const res = await appointmentService.fetchSalesSources();
      const oldSaleSources: string[] = res.data.data;
      const clientsRes = await alliancesService.fetchClients();
      const clients = clientsRes.data;
      const clientsSalesSource = clients.map((client) => client.sales_source);
      const mergedSaleSources = new Set(
        oldSaleSources.concat(newSalesSources).concat(clientsSalesSource)
      );
      const mergedSalesSourceSorted = Array.from(mergedSaleSources).sort(
        (a, b) => {
          return a.localeCompare(b);
        }
      );
      setSalesSourcesList([...mergedSalesSourceSorted]);
    } catch (err) {
      await Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ha ocurrido un error al eliminar la cita del dashboard de totalpack",
        allowOutsideClick: false,
      });
    }
  };

  const displayableBeginDate = (): string => {
    return moment(appointment.local_begin_date, "YYYY-MM-DD HH:mm:ss").format(
      "DD/MM/YYYY HH:mm"
    );
  };

  const displayableEndHour = (): string => {
    return moment(
      appointment.local_work_period_max_lateness,
      "YYYY-MM-DD HH:mm:ss"
    ).format("HH:mm");
  };


  const displayDirection = (): JSX.Element => {
    if (appointment?.target_residence_type === "apartment") {
      return (
        <h2>
          <ApartmentIcon></ApartmentIcon>
          <span>
            {" "}
            <Bold>{appointment.target_address}</Bold> -{" "}
            {appointment?.target_apartment_number}
          </span>
        </h2>
      );
    } else {
      return (
        <h2>
          <HomeIcon></HomeIcon>
          <span>
            {" "}
            <Bold>{appointment?.target_address}</Bold>
          </span>
        </h2>
      );
    }
  };

  useEffect(() => {
    getSalesSources();
  }, []);

  return (
    <Fragment>
      <h1>Cita de {appointment?.nurse} con:</h1>
      <h2>
        <Bold>{mainUserFullName}</Bold>
        <span> el día </span>
        <Bold>
          {displayableBeginDate()} - {displayableEndHour()} - {timezone}
        </Bold>
      </h2>
      {displayDirection()}
    </Fragment>
  );
};

export default Header;

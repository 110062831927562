import React, { useState } from "react";
import styled from "styled-components";

// typings
import {
  CheckoutLabService,
} from "../../../utils/interfaces/LabServices";

// components
import Flex from "../../Containers/Flex";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FormControlLabel, FormGroup, Switch } from "@material-ui/core";

const SAutocomplete = styled(Autocomplete)`
  width: 100%;
  margin-right: 1rem;
`;

interface Props {
  allLabServices: CheckoutLabService[];
  setSelectedLabService: (labService: CheckoutLabService) => void;
  setFonasa: (isFonasa: boolean) => void;
  fonasa: boolean;
}

const NewLabServiceSelector = (props: Props): JSX.Element => {
  const [addLabServiceSelect, setAddLabServiceSelect] = useState<{
    id: string;
    name: string;
  }>({ id: "", name: "" });

  const idToService = {};
  props.allLabServices.forEach((labService) => {
    idToService[labService.id] = labService.name;
  });

  const handleChange = (event) => {
    props.setFonasa(event.target.checked);
  };

  return (
    <Flex direction="column">
      <Flex justify="space-between" align="center" margin="0.25rem 0rem">
        <SAutocomplete
          id="combo-box-demo"
          options={props.allLabServices}
          defaultValue={"Selecciona un examen"}
          getOptionLabel={(option: any) => {
            return option.name
              ? `${option.name} - $${option.price} - ${option.lab_name} ${option.widgets}`
              : "Selecciona un examen";
          }}
          value={addLabServiceSelect}
          onChange={(event: any, newValue: any) => {
            setAddLabServiceSelect(newValue);
            props.setSelectedLabService(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} helperText="Examen" variant="standard" />
          )}
        />
        <FormGroup>
          <FormControlLabel
            control={<Switch onChange={handleChange} checked={props.fonasa}/>}
            label="Fonasa"
          />
        </FormGroup>
      </Flex>
    </Flex>
  );
};

export default NewLabServiceSelector;

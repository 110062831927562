const contactChannelMapper = (contactChannel: string | undefined) => {
  let mappedContactChannel: string;
  switch (contactChannel) {
    case "whatsapp":
      mappedContactChannel = "Whatsapp";
      break;
    case "call_center":
      mappedContactChannel = "Call Center";
      break;
    case "intercom":
      mappedContactChannel = "Intercom";
      break;
    case "medical_representative":
      mappedContactChannel = "Visitador Médico";
      break;
    case "marketing_campaign":
      mappedContactChannel = "Campaña marketing";
      break;
    case "alliance":
      mappedContactChannel = "Alianza";
    default:
      mappedContactChannel = "No especificado";
      break;
  }
  return mappedContactChannel;
};

export default contactChannelMapper;

import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Content, ContentTitleWrapper } from "../Styles/Content";
import { navigate } from "gatsby";

// components
import { numberWithPeriods } from "../../../utils/numbers";
import Bold from "../../../components/Typography/Bold";
import ReassignAppointment from "../../../components/Appointments/Reassign/ReassingAppointment";
import BoletaOrFactura from "../../../components/Appointments/BoleraOrFactura";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ChangeAppointmentAddress from "../../../components/Appointments/ChangeAddress";
import contactChannelMapper from "../../../utils/mappers/contactChannelMapper";
import { AppointmentV2, Payment } from "../../../utils/interfaces/Appointment";
import { AppointmentPatientV2 } from "../../../utils/interfaces/AppointmentPatient";
import { appointmentAPI } from "../../../utils/api/v2";
import moment from "moment";
import { AxiosError } from "axios";
import { TIMEZONE_TO_COUNTRY } from "../../../utils/data/constants";
import appointmentService from "../../../utils/api/v1/appointmentService";
import Swal from "sweetalert2";
import Flex from "../../Containers/Flex";

interface AppointmentInfoProps {
  appointmentId: string;
  appointment: AppointmentV2;
  setAppointment: Dispatch<SetStateAction<AppointmentV2 | undefined>>;
  setError: Dispatch<SetStateAction<AxiosError | undefined>>;
  cancelComponentRef: React.MutableRefObject<any>;
  payments: Payment[];
  appointmentPatients: AppointmentPatientV2[];
}

const AppointmentInfo = ({
  appointmentId,
  appointment,
  setAppointment,
  setError,
  cancelComponentRef,
  payments,
  appointmentPatients,
}: AppointmentInfoProps) => {
  const [copago, setCopago] = useState<number | string>("No calculado");
  const [isCruzBlanca, setIsCruzBlanca] = useState<boolean>(false);

  const fetchInsuranceComunes = async () => {
    try {
      const req = await appointmentAPI.getInsuranceComunes(appointmentPatients[0].patient.insurance);
      return req.data;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const checkCruzBlancaAbled = async () => {
      if (appointmentPatients.length === 1 &&
        appointmentPatients[0].patient.insurance === "cruzblanca") {
        const resp = await fetchInsuranceComunes();
        if (resp !== undefined) {
          const comuneList = resp.map((comuna) => comuna.name);
          if (comuneList.includes(appointment.comuna)) {
            setIsCruzBlanca(true);
            if (appointmentPatients[0].copago !== null){
              setCopago(appointmentPatients[0].copago);
            }
          }
        }
      }
    };
    checkCruzBlancaAbled();
  }, [appointmentPatients]);

  const timezone = useMemo(() => {
    if (appointment && appointment.safe_timezone) {
      return TIMEZONE_TO_COUNTRY[appointment.safe_timezone];
    }
    return "";
  }, [appointment]);

  const displayableBeginDate = (): string => {
    if (!appointment) {
      return "";
    }
    return moment(appointment.local_begin_date, "YYYY-MM-DD HH:mm:ss").format(
      "DD/MM/YYYY HH:mm"
    );
  };

  const handleCancelButton = () => {
    cancelComponentRef?.current?.openDialog();
  };

  const handleGetCopago = async () => {
    try {
      const res = await appointmentService.totalpackValuation(appointmentId);
      if (res.data) {
        setCopago(res.data.data.copago);
        await Swal.fire({
          icon: "success",
          title: "Copago obtenido con éxito",
        });
        window.location.reload();
      }
    } catch (err) {
      await Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ha ocurrido un error al calcular el copago. Para más detalle, ver los errores de Totalpack en el paciente",
      });
      window.location.reload();
    }
  };

  const services = [
    ...appointmentPatients
      .map((ap) =>
        ap.appointment_patient_services.map((aps) => aps.lab_service.id)
      )
      .flat(),
    ...appointmentPatients
      .map((ap) =>
        ap.appointment_patient_item_packs.map((app) =>
          app.pack.items.map((item) => item)
        )
      )
      .flat(2),
  ];

  function parseDiscount(discount: string | undefined, method: string) {
    try {
      if (method === "percentage") {
        return `${parseFloat(discount || "0") * 100}%`;
      } else if (method === "amount") {
        return `$${parseFloat(discount || "0")}`;
      }
    } catch (e) {
      console.error(e);
    }
    return discount;
  }

  return (
    <Fragment>
      <Content>
        <ContentTitleWrapper>
          <h3>Cita</h3>
        </ContentTitleWrapper>
        <p>
          <Bold>Hora: </Bold> {displayableBeginDate()} - {timezone}
        </p>
        <p>
          <Bold>Dirección: </Bold> {appointment?.target_address}
        </p>
        <p>
          <Bold>Tipo de residencia:</Bold>{" "}
          {appointment?.target_residence_type === "apartment"
            ? "Departamento"
            : "Casa"}
        </p>
        {appointment?.target_residence_type === "apartment" ? (
          <p>
            <Bold>Número de departamento: </Bold>{" "}
            {appointment?.target_apartment_number}
          </p>
        ) : (
          ""
        )}
        <p>
          <Bold>Canal de contacto:</Bold>{" "}
          {contactChannelMapper(appointment?.contact_channel || "")}
        </p>
        <p>
          <Bold>Información adicional: </Bold>{" "}
          {appointment?.target_address_additional_info}
        </p>
        <div>
          <Bold>Información de Cupón: </Bold>{" "}
          {appointment?.referrer_snapshot.length === 0 && (
            <div>no se aplicó un cupón</div>
          )}
          {appointment?.referrer_snapshot.length !== 0 && (
            <div>
              <p>Código: {appointment.referrer_snapshot[0].referrer}</p>
              {appointment.referrer_snapshot[0].method === "percentage" && (
                <p>
                  Porcentaje de descuento:{" "}
                  {`${appointment.referrer_snapshot[0].discount * 100}%` ||
                    parseDiscount(
                      appointment.referrer_snapshot[0].snapshot_values.discount,
                      appointment.referrer_snapshot[0].method
                    )}
                </p>
              )}
              {appointment.referrer_snapshot[0].method === "amount" && (
                <p>
                  Monto de descuento:{" "}
                  {`$${appointment.referrer_snapshot[0].discount}` ||
                    parseDiscount(
                      appointment.referrer_snapshot[0].snapshot_values.discount,
                      appointment.referrer_snapshot[0].method
                    )}
                </p>
              )}
              <p>
                Monto mínimo:{" "}
                {
                  appointment.referrer_snapshot[0].snapshot_values
                    .minimum_purchase
                }
              </p>
            </div>
          )}
        </div>
        <p>
          <Bold>Pagos</Bold>
        </p>
        {payments?.map((payment, index: number) => {
          if (payment.status === "Payment Successful") {
            if (payment.payment_gateway) {
              return (
                <p key={index}>
                  Monto pagado con {payment.payment_gateway}: $
                  {numberWithPeriods(payment.amount)}
                </p>
              );
            } else {
              return (
                <p key={payment.id}>
                  Sin información de método de pago: $
                  {numberWithPeriods(payment.amount)}
                </p>
              );
            }
          }
        })}
        {appointment?.last_mile && (
          <React.Fragment>
            <h5>Última milla</h5>
            <p>
              <Bold>Número de folio: </Bold> {appointment.last_mile.folio}
            </p>
            <p>
              <Bold>Monto extra: </Bold> $
              {numberWithPeriods(appointment.last_mile.extra_amount)}
            </p>
          </React.Fragment>
        )}
        {isCruzBlanca && (
          <Flex gap="0.5rem" align="center" margin="0 0 0 0.5rem">
            <Bold>Copago:</Bold> {copago}
            <Button
              color="primary"
              variant="contained"
              component="span"
              onClick={handleGetCopago}
            >
              {typeof copago === "number" ? "Recalcular" : "Calcular"} copago
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={typeof copago === "string"}
              component="span"
              onClick={() => {
                navigate(`/appointment/${appointmentId}/assisted-schedule`);
              }}
            >
              Ir a agendamiento asistido
            </Button>
          </Flex>
        )}
      </Content>
    </Fragment>
  );
};;

export default AppointmentInfo;

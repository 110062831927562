import React, { useState, useEffect, useRef } from "react";
import { AxiosError } from "axios";
import _ from "lodash";
import labService from "../../../utils/api/v1/labService";
// typings
import {
  appointmentAPI,
  appointmentCommentAPI,
  appointmentPatientAPI,
  paymentAPI,
  receiptAPI,
} from "../../../utils/api/v2";
import {
  AppointmentV2,
  Comment,
  Payment,
  AppointmentTimelineEvent,
  Receipt,
} from "../../../utils/interfaces/Appointment";
import { Laboratory } from "../../../utils/interfaces/Laboratory";
import { AppointmentPatientV2 } from "../../../utils/interfaces/AppointmentPatient";
// components
import PatientInformationList from "../../../components/Appointments/Detail/PatientInformationList";
import PrivateRoute from "../../../components/Authentication/PrivateRoute";
import Button from "@material-ui/core/Button";
import LoadingError from "../../../components/Loaders/LoadingError";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Swal from "sweetalert2";
import ReceiptSection from "../../../components/Appointments/ReceiptSection";
import CancelAppointment from "../../../components/Appointments/Cancel/CancelAppointment";
import AppointmentClientOrigin from "../../../components/Appointments/Detail/ClientOrigin";
import {
  ContentWrapper,
  Content,
  ContentTitleWrapper,
} from "../../../components/Appointments/Styles/Content";
import NewPatient from "../../../components/Patients/NewPatient";
import OperativeData from "../../../components/Appointments/OperativeData";
import {
  Header,
  Actionables,
  AppointmentInfo,
  Payments,
} from "../../../components/Appointments/Detail";

// styles
import {
  Wrapper,
  TitleWrapper,
  CommentCell,
  DeleteCommentButton,
  TimelineEventCell,
} from "../../../assets/styles/Appointment";
import BillSection from "../../../components/Appointments/BillSection";
import { COUNTRY_TO_TIMEZONE, COUNTRIES } from "../../../utils/data/constants";

interface AppointmentDetailProps {
  id: string;
}

const AppointmentDetail = ({ id }: AppointmentDetailProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [hasImage, setHasImage] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError>();
  const [comments, setComments] = useState<Comment[]>([]);
  const [loadingComments, setLoadingComments] = useState<boolean>(false);
  const [reloadOperativeData, setReloadOperativeData] = useState(true);
  // @ts-ignore
  const [appointment, setAppointment] = useState<AppointmentV2 | undefined>();
  const [labs, setLabs] = useState<Laboratory[]>([]);
  const cancelComponentRef = useRef<any>(null);

  const [appointmentPatients, setAppointmentPatients] = useState<
    AppointmentPatientV2[]
  >([]);
  const [timelineEvents, setTimelineEvents] = useState<
    AppointmentTimelineEvent[]
  >([]);
  const [payments, setPayments] = useState<Payment[]>([]);
  const [receipts, setReceipts] = useState<Receipt[]>([]);

  const reloadPatients = async () => {
    setLoading(true);
    try {
      const appointmentRes = await appointmentAPI.get(id);
      setAppointment(appointmentRes.data);
      const appointmentPatientsRes = await appointmentAPI.appointmentPatients(
        id,
      );
      setAppointmentPatients(appointmentPatientsRes.data);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchAppointmentRelatedData = async () => {
      try {
        if (appointment) {
          if (appointment.appointment_patients.length > 0) {
            const appointmentPatientReq = await appointmentAPI.appointmentPatients(
              id,
            );
            setAppointmentPatients(appointmentPatientReq.data);
          }
          if (appointment.payments.length > 0) {
            const paymentsReq = await paymentAPI.list(appointment.payments);
            setPayments(paymentsReq.data);
          }
          if (appointment.receipts.length > 0) {
            const receiptsReq = await receiptAPI.list(appointment.receipts);
            setReceipts(receiptsReq.data);
          }
          const commentReq = await appointmentAPI.listComments(id);
          setComments(commentReq.data);
        }
      } catch (err) {
        console.log(err);
        setError(err);
      }
    };
    fetchAppointmentRelatedData();
  }, [appointment]);

  const fetchData = async (): Promise<void> => {
    setLoading(true);
    try {
      const res = await appointmentAPI.get(id);
      setAppointment(res.data);
      setTimelineEvents(res.data.timeline_events);
    } catch (err) {
      console.log(err);
      setError(err);
    }
    setLoading(false);
  };

  const fecthLabs = async (): Promise<void> => {
    try {
      const labsData = await labService.fetchLabs(1000);
      setLabs(labsData.data.results);
    } catch (err) {
      console.log(err);
      setError(err);
    }
  };

  const createComment = async (): Promise<void> => {
    try {
      Swal.fire({
        icon: "warning",
        title: "Ingresa el comentario que quieres dejar",
        input: "textarea",
        confirmButtonText: "Comentar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then(async (res) => {
        if (res.isDenied || res.isDismissed) {
          return;
        }

        if (res.value === null || res.value === "") {
          alert("el comentario debe tener un valor");
        } else {
          setLoadingComments(true);
          try {
            const req = await appointmentAPI.createComment(id, {
              comment: res.value,
            });
            setComments((prevComments) => [...prevComments, req.data]);
          } catch (err) {
            console.log(err);
            setError(err);
          }
          setLoadingComments(false);
        }
      });
    } catch (err) {
      console.log(err);
      setError(err);
    }
  };

  const deleteComment = async (commentId: string): Promise<void> => {
    try {
      const res = await Swal.fire({
        icon: "warning",
        title: "¿Estás seguro de que quieres eliminar este comentario?",
        confirmButtonText: "Si, eliminar",
        showCancelButton: true,
        cancelButtonText: "No",
      });
      if (res.value) {
        await appointmentCommentAPI.delete(commentId);
        setComments((prevComments) => {
          return prevComments.filter((comment) => comment.id !== commentId);
        });
        await Swal.fire({
          icon: "success",
          title: "Comentario eliminado",
        });
      }
    } catch (err) {
      await Swal.fire({
        icon: "error",
        title: "Error",
        text: "No puedes elminar un comentario que no es tuyo",
      });
    }
  };

  useEffect((): void => {
    fetchData();
    fecthLabs();
  }, []);

  const renderTimelineEvents = () => (
    <div style={{ marginTop: 20 }}>
      {timelineEvents.map((event: AppointmentTimelineEvent, i: number) => (
        <TimelineEventCell key={i}>
          <p>{parseDatetime(event.created_at)}</p>
          <p>{event.tag}</p>
          <p>{event.owner?.fullname}</p>
        </TimelineEventCell>
      ))}
    </div>
  );

  const renderExistingComments = () => (
    <div style={{ marginTop: 20 }}>
      {comments.map((comment: Comment, i: number) => (
        <CommentCell key={i} style={{ width: "100%" }}>
          <p>{comment?.user?.fullname}</p>
          <p>{parseDatetime(comment.created_at)}</p>
          <p>{comment.comment}</p>
          <DeleteCommentButton
            onClick={(e) => {
              e.preventDefault();
              deleteComment(comment.id);
            }}
          >
            borrar
          </DeleteCommentButton>
        </CommentCell>
      ))}
    </div>
  );

  const parseDatetime = (datetime: string) => {
    const timeZone = appointment && COUNTRIES.includes(appointment?.country || "") ?
      COUNTRY_TO_TIMEZONE[appointment.country]
      : "America/Santiago";
    const date = new Date(datetime);
    const dateTimeString = date.toLocaleTimeString("it-IT", { timeZone }).split(" ")[0];
    const dateString = date.toLocaleDateString("en-US", { timeZone });
    const timeString = dateTimeString.match(/([0-9]{2}:[0-9]{2})/)?.[0];
    return dateString + " " + timeString;
  };

  return (
    <PrivateRoute>
      <LoadingError loading={loading} />
      <CancelAppointment id={id} ref={cancelComponentRef} />
      {!loading && appointment && (
        <Wrapper variant="outlined">
          <TitleWrapper>
            <Header
              appointmentPatients={appointmentPatients}
              appointmentId={id}
              appointment={appointment}
              setAppointment={setAppointment}
              setError={setError}
            />
          </TitleWrapper>
          <ContentWrapper>
            <PatientInformationList
              dealGroupedMedicalOrders={appointment.deals.map(
                (deal) => deal.grouped_orden_medica
              )}
              setAppointmentPatients={setAppointmentPatients}
              appointmentPatients={appointmentPatients}
              country={appointment.country}
              appointment={appointment}
              appointmentId={id}
              hasImage={hasImage}
              setHasImage={setHasImage}
              setReloadOperativeData={setReloadOperativeData}
              setError={setError}
            />
            <NewPatient
              setError={setError}
              updateAppointmentData={reloadPatients}
              country={appointment.country}
              appointment_id={id}
              setReloadOperativeData={setReloadOperativeData}
            />
            <AppointmentInfo
              payments={payments}
              appointment={appointment}
              appointmentId={id}
              setAppointment={setAppointment}
              setError={setError}
              cancelComponentRef={cancelComponentRef}
              appointmentPatients={appointmentPatients}
            />
            <Payments
              payments={payments}
              appointmentId={id}
              appointment={appointment}
              setAppointment={setAppointment}
              setError={setError}
            />
            <Content>
              {appointment?.country === "México" && (
                <BillSection appointmentPatients={appointmentPatients} />
              )}
            </Content>
            <Content>
              <ContentTitleWrapper>
                <h3>Comentarios</h3>
                <ButtonGroup color="primary" variant="text" size="small">
                  <Button disabled={loadingComments} onClick={createComment}>
                    Agregar comentario
                  </Button>
                </ButtonGroup>
              </ContentTitleWrapper>
              {loading ? <p>Cargando comentarios</p> : renderExistingComments()}
            </Content>
            <Content>
              <ContentTitleWrapper>
                <h3>Events</h3>
              </ContentTitleWrapper>
              {renderTimelineEvents()}
            </Content>
            {appointment?.is_operative && (
              <OperativeData
                id={id}
                reload={reloadOperativeData}
                setReload={setReloadOperativeData}
              />
            )}
          </ContentWrapper>
        </Wrapper>
      )}
    </PrivateRoute>
  );
};

export default AppointmentDetail;

import React, { Dispatch, Fragment, SetStateAction } from "react";
import { AppointmentV2 } from "../../../utils/interfaces/Appointment";
import PatientInformation from "../Detail/PatientInformation";
import { AxiosError } from "axios";
import { AppointmentPatientV2 } from "../../../utils/interfaces/AppointmentPatient";

interface PatientInformationListProps {
  appointmentPatients: AppointmentPatientV2[];
  setAppointmentPatients: Dispatch<SetStateAction<AppointmentPatientV2[]>>;
  appointmentId: string;
  country: string;
  appointment: AppointmentV2;
  setHasImage: Dispatch<SetStateAction<boolean>>;
  hasImage: boolean;
  setReloadOperativeData: Dispatch<SetStateAction<boolean>>;
  dealGroupedMedicalOrders: string[];
  setError: Dispatch<SetStateAction<AxiosError | undefined>>;
}

const PatientInformationList = ({
  appointmentPatients,
  setAppointmentPatients,
  country,
  appointmentId,
  hasImage,
  setHasImage,
  setReloadOperativeData,
  dealGroupedMedicalOrders,
  setError,
}: PatientInformationListProps) => {


  return (
    <Fragment>
      {appointmentPatients.map(
        (appointmentPatient, index: number) => {
          return (
            <PatientInformation
              setError={setError}
              dealGroupedMedicalOrders={dealGroupedMedicalOrders}
              setAppointmentPatients={setAppointmentPatients}
              appointmentId={appointmentId}
              country={country}
              appointmentPatient={appointmentPatient}
              key={index}
              hasImage={hasImage}
              setHasImage={setHasImage}
              setReloadOperativeData={setReloadOperativeData}
            />
          );
        }
      )}
    </Fragment>
  );
};

export default PatientInformationList;

import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { navigate } from "gatsby-link";
import { Link, Chip } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import { formatDate, calculateAge } from "../../../utils/dates/formatDate";
import DeleteIcon from "@material-ui/icons/Delete";
import UploadAndDisplayImage from "../UploadImage";
import { insuranceNameText } from "../../../utils/texts";
// typings
import { AxiosError } from "axios";

// components
import Bold from "../../Typography/Bold";
import ResultDisplayAndUpload from "../ResultDisplayAndUpload";
import {
  Content,
  LoaderWrapper,
  PatientActionModal,
  PatientActionModalBody,
  PatientActionModalTitle,
  PatientActionSection,
  PatientContentTitle,
} from "../Styles/Content";
import Swal from "sweetalert2";
import NewLabService from "../LabServices/NewLabService";
import UploadMedicalOrder from "../UploadMedicalOrder";
import TotalpackPatientInfo from "../TotalpackPatientInfo";
import { UploadDoctorInfo } from "../UploadDoctorInfo";
import Flex from "../../Containers/Flex";

// utils
import { capitalize } from "../../../utils/texts";
import { AppointmentPatientV2 } from "../../../utils/interfaces/AppointmentPatient";
import styled from "styled-components";
import { appointmentAPI, appointmentPatientAPI } from "../../../utils/api/v2";
import EditServiceInsurance from "./EditInsurance/EditServiceInsurance";
import AddPack from "../../Pack/AddPack";

const CustomDeleteIcon = styled(DeleteIcon)`
  color: #0000008a;
  cursor: pointer;
`;

const PatientInformation = ({
  appointmentPatient,
  setAppointmentPatients,
  appointmentId,
  country,
  hasImage,
  setHasImage,
  setReloadOperativeData,
  dealGroupedMedicalOrders,
  setError,
}: {
  appointmentPatient: AppointmentPatientV2;
  setAppointmentPatients: Dispatch<
    SetStateAction<AppointmentPatientV2[]>
  >;
  appointmentId: string;
  country: string;
  hasImage: boolean;
  setHasImage: (hasImage: boolean) => void;
  setReloadOperativeData: Dispatch<SetStateAction<boolean>> | undefined;
  dealGroupedMedicalOrders: string[];
  setError: Dispatch<SetStateAction<AxiosError | undefined>>;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [fileArray, setFileArray] = useState<Array<File> | undefined>([]);
  const [hasFonasaService, setHasFonasaService] = useState<boolean>(false);
  const [fastHours, setFastHours] = useState<number>(0);
  const [showRemoveFonasaModal, setShowRemoveFonasaModal] =
    useState<boolean>(false);
  const [groupedMedicalOrders, setGroupedMedicalOrders] = useState<string[]>(
    []
  );

  const patient = appointmentPatient.patient;
  const symptoms = appointmentPatient.symptoms;
  const reason = appointmentPatient.reason;
  const comments = appointmentPatient.comments;
  const active = appointmentPatient.active;
  const referralURL = "https://referidos.examedi.com/referred/?code=";
  const onReferralClick = async (e) => {
    e.preventDefault();
    await navigator.clipboard.writeText(referralURL + patient.id);
    alert("Link de referidos copiado con éxito");
  };
  const reloadAppointmentPatient = async () => {

    const response = await appointmentPatientAPI.patch(appointmentPatient.id, {});
    setAppointmentPatients((prev) => {
      const index = prev.indexOf(appointmentPatient);
      const newAppointmentPatients = [...prev];
      newAppointmentPatients[index] = response.data;
      return newAppointmentPatients;
    });
  }
  const changePatientStatus = async (active) => {
    try {
      const check = await Swal.fire({
        title: `¿Estás seguro que deseas ${
          !active && "des"
        }activar este paciente?`,
        icon: "warning",
        showCancelButton: true,
      });
      if (!check.isConfirmed) {
        return;
      }
      const response = await appointmentPatientAPI.patch(
        appointmentPatient.id,
        {
          active,
        }
      );
      setAppointmentPatients((prev) => {
        const index = prev.indexOf(appointmentPatient);
        const newAppointmentPatients = [...prev];
        newAppointmentPatients[index] = response.data;
        return newAppointmentPatients;
      });

      await Swal.fire({
        title: "¡Hecho!",
      });
      setReloadOperativeData && setReloadOperativeData(true);
    } catch (e) {
      setError(e);
      console.log(e);
    }
  };

  const deleteService = async (labServiceId: string) => {
    try {
      const check = await Swal.fire({
        title: "¿Estás seguro que deseas eliminar este servicio?",
        icon: "warning",
        showCancelButton: true,
      });
      if (!check.isConfirmed) {
        return;
      }

      await appointmentPatientAPI.deleteLabService(
        appointmentPatient.id,
        labServiceId
      );
      setAppointmentPatients((prev) => {
        const index = prev.indexOf(appointmentPatient);
        const newAppointmentPatients = [...prev];
        newAppointmentPatients[index] = {
          ...newAppointmentPatients[index],
          appointment_patient_services: newAppointmentPatients[
            index
          ].appointment_patient_services.filter(
            (aps) => aps.lab_service.id !== labServiceId
          ),
        };
        return newAppointmentPatients;
      });

      await Swal.fire({
        title: "¡Hecho!",
      });
    } catch (e) {
      console.log(e);
      setError(e);
    }
  };

  const deletePack = async (apptPatientPackId: string) => {
    try {
      const check = await Swal.fire({
        title: "¿Estás seguro que deseas eliminar este pack?",
        icon: "warning",
        showCancelButton: true,
      });
      if (!check.isConfirmed) {
        return;
      }

      await appointmentPatientAPI.deletePack(
        appointmentPatient.id,
        apptPatientPackId
      );
      setAppointmentPatients((prev) => {
        const index = prev.indexOf(appointmentPatient);
        const newAppointmentPatients = [...prev];
        newAppointmentPatients[index] = {
          ...newAppointmentPatients[index],
          appointment_patient_item_packs: newAppointmentPatients[
            index
          ].appointment_patient_item_packs.filter(
            (apptPatientPack) => apptPatientPack.id !== apptPatientPackId
          ),
        };
        return newAppointmentPatients;
      });

      await Swal.fire({
        title: "¡Hecho!",
      });
    } catch (e) {
      console.log(e);
      setError(e);
    }
  };

  const removeFonasaService = async () => {
    setLoading(true);
    try {
      const response = await appointmentPatientAPI.patch(
        appointmentPatient.id,
        {
          services: appointmentPatient.appointment_patient_services.map(
            (aps) => ({ id: aps.lab_service.id, is_fonasa: false })
          ),
        }
      );
      setAppointmentPatients((prev) => {
        const index = prev.indexOf(appointmentPatient);
        const newAppointmentPatients = [...prev];
        newAppointmentPatients[index] = response.data;
        return newAppointmentPatients;
      });
      await Swal.fire({
        title: "¡Hecho!",
      });
    } catch (e) {
      console.log(e);
      setError(e);
    }
    setLoading(false);
  };

  const changeAllServicesInsurance = async (isFonasa: boolean, insurance: string) => {
    setLoading(true);
    try {
      const response = await appointmentPatientAPI.patch(
        appointmentPatient.id,
        {
          services: appointmentPatient.appointment_patient_services.map(
            (aps) => {
              return { id: aps.lab_service.id, is_fonasa: isFonasa, insurance: insurance }
            }
          ),
        }
      );
      setAppointmentPatients((prev) => {
        const index = prev.indexOf(appointmentPatient);
        const newAppointmentPatients = [...prev];
        newAppointmentPatients[index] = response.data;
        return newAppointmentPatients;
      }
      );
      await Swal.fire({
        title: "¡Previsión modificada con éxito!",
      });
      window.location.reload();
    } catch (e) {
      console.log(e);
      setError(e);
    }
  };

  const triggerOMReady = async () => {
    const sentOM = await appointmentAPI.sendOMReady({appointment_id: appointmentId});
  }

  const changeServiceInsurance = async (patientServiceId: string, isFonasa: boolean, insurance: string) => {
    setLoading(true);
    try {
      const response = await appointmentPatientAPI.patch(
        appointmentPatient.id,
        {
          services: appointmentPatient.appointment_patient_services.map(
            (aps) => {
              if (aps.id === patientServiceId) {
                return { id: aps.lab_service.id, is_fonasa: isFonasa, insurance: insurance }
              } else {
                return { id: aps.lab_service.id, is_fonasa: aps.is_fonasa, insurance: aps.insurance }
              }
            }
          ),
        }
      );
      setAppointmentPatients((prev) => {
        const index = prev.indexOf(appointmentPatient);
        const newAppointmentPatients = [...prev];
        newAppointmentPatients[index] = response.data;
        return newAppointmentPatients;
      }
      );
      await Swal.fire({
        title: "¡Previsión modificada con éxito!",
      });
    } catch (e) {
      console.log(e);
      setError(e);
    }
  };

  useEffect(() => {
    if (
      country === "Chile" &&
      appointmentPatient.appointment_patient_services.find(
        (item) => item.is_fonasa
      ) !== undefined
    ) {
      setHasFonasaService(true);
    } else {
      setHasFonasaService(false);
    }
  }, [appointmentPatient.appointment_patient_services, country]);

  useEffect(() => {
    const fastHoursServices =
      appointmentPatient.appointment_patient_services.reduce((prev, curr) => {
        return prev > curr.lab_service.fast_hours
          ? prev
          : curr.lab_service.fast_hours;
      }, 0);
    const fastHoursPack = appointmentPatient.appointment_patient_item_packs.reduce(
      (prev, curr) => {
        return prev > curr.pack.fast_hours ? prev : curr.pack.fast_hours;
      },
      0
    );
    const fastHours =
      fastHoursServices > fastHoursPack ? fastHoursServices : fastHoursPack;
    setFastHours(fastHours as number);

    setGroupedMedicalOrders([
      ...appointmentPatient.grouped_orden_medica,
      ...dealGroupedMedicalOrders,
    ]);
  }, [appointmentPatient]);

  return (
    <Fragment>
      <Content>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h3 style={!active ? { color: "#D1CED1" } : {}}>
            Paciente {!active && "desactivado"}
          </h3>
        </div>
        <p style={!active ? { color: "#D1CED1" } : {}}>
          <Bold>Nombre: </Bold> {patient.first_name} {patient.second_name}{" "}
          {patient.last_name} {patient.second_last_name}
        </p>
        <p style={!active ? { color: "#D1CED1" } : {}}>
          <Bold>{capitalize(patient.document_type)}: </Bold>{" "}
          {patient.document_number}
        </p>
        <p style={!active ? { color: "#D1CED1" } : {}}>
          <Bold>Sexo: </Bold>{" "}
          {patient.gender === "male" ? "Masculino" : "Femenino"}
        </p>
        <p style={!active ? { color: "#D1CED1" } : {}}>
          <Bold>Teléfono: </Bold>{" "}
          <a target="_blank" href={`https://wa.me/${patient.phone}`}>
            {patient.phone}
          </a>
        </p>
        <p style={!active ? { color: "#D1CED1" } : {}}>
          <Bold>Email: </Bold> {patient.email}
        </p>
        {active && (
          <div>
            <p>
              <Bold>Edad: </Bold> {calculateAge(patient.date_of_birth)} años
            </p>
            <p>
              <Bold>Fecha de nacimiento: </Bold>{" "}
              {formatDate(patient.date_of_birth)}
            </p>
            {patient.insurance && (
              <p>
                <Bold>Previsión: </Bold>
                <Chip label={patient.insurance} color="primary" />
              </p>
            )}

            {symptoms && (
              <p>
                <Bold>Síntomas: </Bold> {symptoms}
              </p>
            )}
            {reason && (
              <p>
                <Bold>Motivo de toma de examen: </Bold> {reason}
              </p>
            )}
            {comments && (
              <p>
                <Bold>Otros comentarios: </Bold>
                {comments}
              </p>
            )}
            {fastHours && (
              <p>
                <Bold>Horas de ayuno: </Bold> {fastHours}
              </p>
            )}

            <PatientContentTitle active={active}>
              Exámenes Solicitados
            </PatientContentTitle>

            <Grid
              container
              spacing={1}
              style={{ padding: "4px", marginBottom: "1rem" }}
            >
              {appointmentPatient.appointment_patient_services.map(
                (item, index: number) => {
                  return (
                    <Grid item md={4} key={index}>
                      <Card style={{ minHeight: "17rem" }}>
                        <List>
                          <ListItem dense>
                            <ListItemText
                              primary={
                                <span>
                                  {item.lab_service.display_name} -{" "}
                                  {item.lab_service.lab_name}
                                </span>
                              }
                              secondary={
                                <React.Fragment>
                                  <Typography component="span" variant="body2">
                                    {item.lab_service.external_reference &&
                                      `Código: ${item.lab_service.external_reference} | `}
                                  </Typography>
                                  {country !== "México" && (
                                    <Typography component="span" variant="body2">
                                      Previsión:
                                      {item.insurance !== "fonasa" && (
                                        <span>
                                          {insuranceNameText(item.insurance)}
                                        </span>
                                      )}
                                      {(item.insurance === "fonasa" || item.is_fonasa) && (
                                        ` Fonasa - Código ${item.lab_service.service_code}`
                                      )}
                                    </Typography>
                                  )}
                                </React.Fragment>
                              }
                            />

                            <CustomDeleteIcon
                              onClick={() => deleteService(item.lab_service.id)}
                            />
                          </ListItem>
                          {country !== "México" && (
                            <EditServiceInsurance
                              mode="singleTest"
                              changeAllServicesInsurance={changeAllServicesInsurance}
                              changeServiceInsurance={changeServiceInsurance}
                              is_fonasa={item.is_fonasa}
                              lab_service={item.lab_service}
                              insurance={item.insurance}
                              patientId={patient.id}
                              serviceId={item.id}
                            />
                          )}
                          </List>
                      </Card>
                    </Grid>
                  );
                }
              )}
            </Grid>
            <NewLabService
              setError={setError}
              appointmentPatient={appointmentPatient}
              setAppointmentPatients={setAppointmentPatients}
            />
            <Button
              color="primary"
              variant="contained"
              className="w-full mt-[5px] bg-examedi-blue-strong"
              onClick={() => Swal.fire({
                icon: "warning",
                title: "Enviar orden medica por whatsapp?",
                text: "Esta acción no se puede deshacer",
                confirmButtonText: "Enviar",
                showCancelButton: true,
                cancelButtonText: "Cancelar"
              }).then(async (res)=>{
                if(res.value){
                  const triggered = await triggerOMReady();
                  Swal.fire({
                    icon: "success",
                    title: "Flujo de Whatsapp Enviado"
                  })
                }
              })}
            >
              Asignar servicios para Whatsapp
            </Button>
            {country !== "México" && (
              <EditServiceInsurance
                mode="allTests"
                changeAllServicesInsurance={changeAllServicesInsurance}
                changeServiceInsurance={changeServiceInsurance}
                is_fonasa={true}
                insurance={"fonasa"}
                patientId={patient.id}
              />
            )}
            {hasFonasaService && (
              <Fragment>
                <PatientActionSection>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => setShowRemoveFonasaModal(true)}
                  >
                    Quitar Fonasa
                  </Button>
                </PatientActionSection>
                <Dialog
                  open={showRemoveFonasaModal}
                  onClose={() => setShowRemoveFonasaModal(false)}
                >
                  <PatientActionModal>
                    <PatientActionModalTitle>
                      ¿Estás segur@?
                    </PatientActionModalTitle>
                    <PatientActionModalBody>
                      Todos los exámenes de este paciente quedarán registrados
                      como previsión particular.
                    </PatientActionModalBody>
                    <Flex justify="space-around">
                      {!loading ? (
                        <Fragment>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => setShowRemoveFonasaModal(false)}
                          >
                            Cancelar
                          </Button>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={removeFonasaService}
                          >
                            Confirmar
                          </Button>
                        </Fragment>
                      ) : (
                        <Flex direction="column" align="center">
                          <LoaderWrapper>
                            <CircularProgress color="primary" />
                          </LoaderWrapper>
                        </Flex>
                      )}
                    </Flex>
                  </PatientActionModal>
                </Dialog>
              </Fragment>
            )}

            <PatientContentTitle active={active}>
              Packs Solicitados
            </PatientContentTitle>
            <AddPack
                appointmentId={appointmentId}
                appointmentPatient={appointmentPatient}
                fonasa={hasFonasaService}
                setError={setError}
                reloadAppointmentPatient={reloadAppointmentPatient}

            />
            <Grid style={{ padding: "4px" }}>
              {appointmentPatient.appointment_patient_item_packs.map(
                (item, index: number) => {
                  return (
                    <Grid item md={6} key={index}>
                      <Card style={{ backgroundColor: "khaki" }}>
                        <List>
                          <ListItem dense>
                            <ListItemText
                              primary={
                                <div className="flex flex-column">
                                  <div>Pack: {item.pack.name}</div>
                                  {item.pack.external_reference && (<div>{item.pack.external_reference}</div>)}
                                </div>
                              }
                              // TODO: serialize the items in the pack and get the service code
                              secondary={
                                <React.Fragment>
                                  {country !== "México" && (
                                  <Typography component="span" variant="body2">
                                      {item.is_fonasa
                                      ? "Previsión: Fonasa - Código"
                                        : `Previsión: Particular`}
                                    </Typography>
                                  )}
                                </React.Fragment>
                              }
                            />
                            <CustomDeleteIcon
                              onClick={() => deletePack(item.id)}
                            />
                          </ListItem>
                        </List>
                      </Card>
                    </Grid>
                  );
                }
              )}
            </Grid>

            <div>
              <PatientContentTitle active={active}>
                Imágenes de orden médica
              </PatientContentTitle>
              <UploadMedicalOrder
                setGroupedMedicalOrders={setGroupedMedicalOrders}
                groupedMedicalOrders={groupedMedicalOrders}
                fileArray={fileArray}
                appointmentPatient={appointmentPatient}
                setFileArray={setFileArray}
              />
            </div>
            <UploadDoctorInfo
              appointmentPatient={appointmentPatient.id}
              active={active}
            />
            <ResultDisplayAndUpload
              appointmentId={appointmentId}
              patientId={patient.id}
              country={country}
            />
          </div>
        )}
      </Content>
    </Fragment>
  );
};

export default PatientInformation;

import React, { useMemo, useEffect, useState, Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { format } from "rut.js";
import { onlyNumbers } from "../../utils/numbers";
import { Content } from "../../components/Appointments/Styles/Content";
import { CenteredItem } from "../../assets/styles/Appointment";
// components
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import {
  Button,
  Card,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { Patient } from "../../utils/interfaces/AppointmentPatient";
import { validateEmail } from "../../utils/regex";
import appointmentService from "../../utils/api/v1/appointmentService";
import Swal from "sweetalert2";
import fetch from "../../utils/api/v1/fetchHelper";
import { AxiosError } from "axios";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  align-items: center;

  .MuiFormControl-root {
    margin: 0.5rem 2rem;
  }
`;

interface Props {
  appointment_id: string;
  updateAppointmentData;
  country: string;
  setReloadOperativeData: (reload: boolean) => void | undefined;
  setError: Dispatch<SetStateAction<AxiosError | undefined>>;
}

const NewPatient = (props: Props): JSX.Element => {
  const appointment_id = props.appointment_id;
  const updateAppointmentData = props.updateAppointmentData;
  const [searched, setSearched] = useState<boolean>(false);
  const [found, setFound] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [patientData, setPatientData] = useState<Patient>({
    id: "",
    email: "",
    first_name: "",
    second_name: "",
    last_name: "",
    second_last_name: "",
    phone: "",
    document_type: "",
    document_number: "",
    date_of_birth: "",
    gender: "male",
    country: "",
  });


  const error = useMemo(() => emailError, [emailError]);

  const fetchPatient = async (): Promise<void> => {
    try {
      const res = await appointmentService.searchPatient(
        patientData.document_type,
        patientData.document_number,
        props.country === "México"
      );
      if (res) {
        if (res.data.data.length > 0) {
          setPatientData(res.data.data[0]);
          setFound(true);
        }
      }
      setSearched(true);
    } catch (err) {
      console.log(err);
      props.setError(err)
    }
  };

  const createPatient = async (): Promise<void> => {
    if (error) return;
    await fetch(
      () => {},
      () => {},
      async () => {
        let payload = patientData;
        delete payload.id;
        delete payload.country;

        const birthDate: Date = new Date(payload.date_of_birth);
        birthDate.setHours(12);
        birthDate.setDate(birthDate.getDate() + 1);
        payload.date_of_birth = birthDate.toISOString();
        if (["México", "Mexico"].includes(props.country)) {
          payload.document_type = "generic_id";
        }

        const res = await appointmentService.createPatient(
          props.appointment_id,
          payload
        );
        await Swal.fire({
          title: "¡Hecho!",
        });
        updateAppointmentData();
        setFound(false);
        setSearched(false);
        setPatientData({
          id: "",
          email: "",
          first_name: "",
          second_name: "",
          last_name: "",
          second_last_name: "",
          phone: "",
          document_type: "",
          document_number: "",
          date_of_birth: "",
          gender: "male",
          country: "",
        });
      }
    );
    setFound(false);
    setSearched(false);
    setPatientData({
      id: "",
      email: "",
      first_name: "",
      second_name: "",
      last_name: "",
      second_last_name: "",
      phone: "",
      document_type: "",
      document_number: "",
      date_of_birth: "",
      gender: "male",
      country: "",
    });
    props.setReloadOperativeData && props.setReloadOperativeData(true);
  };

  useEffect(() => {
    if (props.country === "México") {
      const parsedBirthDate = patientData.date_of_birth?.split("-").join("");
      patientData.document_number =
        `${patientData.first_name}${patientData.last_name}${parsedBirthDate}`.toUpperCase();
      if (
        !!patientData.first_name &&
        !!patientData.last_name &&
        !!patientData.date_of_birth
      ) {
        fetchPatient();
      }
    }
  }, [
    patientData.first_name,
    patientData.last_name,
    patientData.date_of_birth,
  ]);

  const addPatient = async (): Promise<void> => {
    await fetch(
      () => {},
      () => {},
      async () => {
        const res = await appointmentService.addPatient(
          props.appointment_id,
          patientData.id ? patientData.id : ""
        );
        await Swal.fire({
          title: "¡Hecho!",
        });
        updateAppointmentData();
        setFound(false);
        setSearched(false);
        setPatientData({
          id: "",
          email: "",
          first_name: "",
          second_name: "",
          last_name: "",
          second_last_name: "",
          phone: "",
          document_type: "",
          document_number: "",
          date_of_birth: "",
          gender: "male",
          country: "",
        });
      }
    );
    props.setReloadOperativeData && props.setReloadOperativeData(true);
  };

  const setErrors = (attribute: string, newValue: string) => {
    if (attribute === "email") {
      setEmailError(!validateEmail(newValue));
    }
  };

  const formChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;
    const attribute = event.target.id;
    setErrors(attribute, newValue);
    setPatientData((prevState: Patient): Patient => {
      let extraProps = {};
      if (prevState.document_type === "ci" && attribute === "document_number") {
        const ci = format(newValue);
        extraProps = {
          document_number: ci,
        };
      }
      if (attribute === "document_type" && newValue === "ci") {
        const ci = format(
          onlyNumbers(
            prevState.document_number ? prevState.document_number : ""
          )
        );
        extraProps = {
          document_number: ci,
        };
      }
      return {
        ...prevState,
        [attribute]: newValue,
        ...extraProps,
      };
    });
  };

  const genderChange = (event): void => {
    setPatientData((prevState: Patient): Patient => {
      const newState = { ...prevState };
      newState.gender = event.target.value;
      return newState;
    });
  };

  return (
    <Content>
      <CenteredItem>
        <Card style={{ padding: "2em" }}>
          <Row>{<p>Nuevo paciente</p>}</Row>
          {props.country !== "México" && (
            <Row>
              <FormControl fullWidth>
                <RadioGroup
                  row={true}
                  value={patientData.document_type}
                  onChange={formChange}
                  defaultValue="ci"
                >
                  <FormControlLabel
                    disabled={searched}
                    value="ci"
                    control={<Radio id="document_type" />}
                    label="RUT"
                  />
                  <FormControlLabel
                    disabled={searched}
                    value="passport"
                    control={<Radio id="document_type" />}
                    label="Pasaporte"
                  />
                </RadioGroup>
              </FormControl>
            </Row>
          )}
          <Row>
            {(patientData.document_type === "ci" ||
              patientData.document_type === undefined) && (
              <TextField
                disabled={searched}
                id="document_number"
                inputProps={{ maxLength: 12 }}
                onChange={formChange}
                value={patientData.document_number}
                helperText="RUT"
                fullWidth
              />
            )}
            {patientData.document_type === "passport" && (
              <TextField
                disabled={searched}
                id="document_number"
                inputProps={{ minLength: 4 }}
                onChange={formChange}
                value={patientData.document_number}
                helperText="Pasaporte"
                fullWidth
              />
            )}
          </Row>
          {searched ? (
            <div>
              <Row>
                <TextField
                  disabled={found}
                  id="first_name"
                  onChange={formChange}
                  value={patientData.first_name}
                  helperText="Primer nombre"
                  fullWidth
                />
                <TextField
                  disabled={found}
                  id="second_name"
                  onChange={formChange}
                  value={patientData.second_name}
                  helperText="Segundo nombre"
                  fullWidth
                />
              </Row>
              <Row>
                <TextField
                  disabled={found}
                  id="last_name"
                  onChange={formChange}
                  value={patientData.last_name}
                  helperText="Apellido"
                  fullWidth
                />
                <TextField
                  disabled={found}
                  id="second_last_name"
                  onChange={formChange}
                  value={patientData.second_last_name}
                  helperText="Segundo apellido"
                  fullWidth
                />
              </Row>
              <Row>
                <TextField
                  label={emailError ? "Email con errores" : null}
                  error={emailError}
                  disabled={found}
                  id="email"
                  onChange={formChange}
                  value={patientData.email}
                  helperText="Correo electrónico"
                  fullWidth
                />
                <TextField
                  disabled={found}
                  id="phone"
                  onChange={formChange}
                  value={patientData.phone}
                  helperText="Teléfono"
                  fullWidth
                />
              </Row>
              <Row>
                <FormControl fullWidth>
                  <Select
                    disabled={found}
                    onChange={genderChange}
                    value={patientData.gender}
                    fullWidth
                  >
                    <MenuItem value="male">Hombre</MenuItem>
                    <MenuItem value="female">Mujer</MenuItem>
                    <MenuItem value="other">Otro</MenuItem>
                  </Select>
                  <FormHelperText>Sexo</FormHelperText>
                </FormControl>
                <TextField
                  fullWidth
                  id="date_of_birth"
                  helperText="Fecha de nacimiento"
                  type="date"
                  onChange={formChange}
                  value={patientData.date_of_birth}
                  disabled={found}
                />
              </Row>
            </div>
          ) : (
            <Row>
              <Button
                style={{ margin: "0 auto" }}
                color="primary"
                variant="contained"
                onClick={() => fetchPatient()}
              >
                {props.country === "México" ? "Añadir Paciente" : "Buscar"}
              </Button>
            </Row>
          )}

          {searched && !found && (
            <Row>
              <Button
                disabled={error}
                style={{ margin: "0 auto" }}
                color="primary"
                variant="contained"
                onClick={() => createPatient()}
              >
                Crear paciente
              </Button>
            </Row>
          )}
          {found && (
            <p style={{ color: "orange" }}>
              Este paciente ya existe en nuestra base de datos.
              <br />
              Conéctalo a esta agenda con un click.
            </p>
          )}
          {found && (
            <Row>
              <Button
                style={{ margin: "0 auto" }}
                color="primary"
                variant="contained"
                onClick={() => addPatient()}
              >
                Conectar paciente
              </Button>
            </Row>
          )}
        </Card>
      </CenteredItem>
    </Content>
  );
};

export default NewPatient;

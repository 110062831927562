import { CartLabService } from "./LabServices";
import { Locale } from "./Locale";
import { MedicalOrder } from "./MedicalOrder";

export enum FiscoClicStatus {
  REQUEST_PENDING = "request-pending",
  REQUESTED_SUCCESSFULLY = "requested-successfully",
  PAYMENTS_PENDING = "payments-pending",
  REQUEST_ERROR = "request-error",
  INTERNAL_ERROR = "internal-error",
}

// TODO: separate types and remove old ones, once we migrated all the code to v2
export interface AppointmentPatientV2 {
  id: string;
  symptoms: string | null;
  reason: string | null;
  comments: string | null;
  active: boolean;
  picture: string | null;
  patient: {
    id: string;
    first_name: string;
    second_name: string;
    last_name: string;
    second_last_name: string;
    date_of_birth: string;
    document_number: string;
    document_type: string;
    email: string;
    insurance: string;
    gender: string;
    phone: string;
  };
  appointment_patient_services: {
    is_fonasa: boolean;
    lab_service: {
      id: string;
      display_name: string;
      fast_hours: number;
      lab_name: string;
      external_reference: string;
      service_code: string;
    };
    insurance: string;
    id: string;
  }[];
  appointment_patient_item_packs: {
    id: any;
    is_fonasa: boolean;
    pack: {
      name: string;
      external_reference: string;
      fast_hours: number;
      items: string[]
    };
  }[];
  grouped_orden_medica: string[];
  copago: number;
  totalpack_request: {
    status: string;
    message: string;
  } | null;
  fiscoclic_request: FiscoClicData | null;
}

export interface FiscoClicData {
  id: string;
  folio?: string;
  amount_paid: number;
  message?: string;
  patient_name: string;
  sending_time: string;
  status: FiscoClicStatus;
}

export interface Patient {
  id?: string;
  email: string;
  first_name: string;
  second_name: string | null;
  last_name: string;
  second_last_name: string | null;
  phone: string;
  document_type: string;
  document_number: string;
  date_of_birth: string;
  gender: "male" | "female" | "other";
  fullname?: string;
  country?: Locale;
  insurance?: string;
}

export interface AppointmentPatient {
  document?: any;
  id?: string;
  symptoms: string;
  reason: string;
  patient: Patient;
  active: boolean;
  comments: string;
  appointmentpatientservice_set: Array<Object>;
  appointmentpatientpack_set: Array<Object>;
  appointment: string;
  medical_orders: MedicalOrder[];
  fiscoclic: FiscoClicData | null;
  totalpack_request_message: string;
  totalpack_request_status: string;
}

export interface DisplayData {
  sentStatusText: string;
  details: {
    title: string;
    text: string;
  }[];
}

export interface AppointmentPatientService {
  id: string;
  service: CartLabService;
  is_fonasa: boolean;
  appointment_patient: string;
}

export interface AddServicePayload {
  msg: string;
};

import React, { useMemo } from "react";
import moment from "moment";

// typing
import {
  AppointmentPatientV2,
  FiscoClicData,
  DisplayData,
  FiscoClicStatus,
} from "../../utils/interfaces/AppointmentPatient";

// components
import { ContentTitleWrapper } from "./Styles/Content";
import {
  MainContainer,
  MainWrapper,
  Title,
  Status,
  InfoWrapper,
  InfoTitle,
  InfoText,
} from "./Styles/BillSection";

// constants
import {
  sentInfo,
  sentErrorInfo,
  folioInfo,
  fiscoClicErrorInfo,
  internalErrorInfo,
  paymentPendingInfo,
  scheduleInfo,
  amountPaidInfo,
  statusInfo,
} from "../../utils/constants/appointment";


interface BillDataProps {
  appointmentPatient: Omit<AppointmentPatientV2, "fiscoclic_request"> & { fiscoclic_request: FiscoClicData };
  lastElement?: boolean;
}

const BillData = ({ appointmentPatient, lastElement }: BillDataProps) => {
  const sendingDateTime = useMemo(() => {
    const parsedSendingTime = moment(appointmentPatient.fiscoclic_request.sending_time);
    const date = parsedSendingTime.format("DD/MM/YYYY");
    const time = parsedSendingTime.format("HH:mm");
    return { date, time };
  }, [appointmentPatient]);

  const currentInfo = useMemo(() => {
    const { fiscoclic_request } = appointmentPatient;
    const wasSentByStatus = ![FiscoClicStatus.REQUEST_PENDING, FiscoClicStatus.PAYMENTS_PENDING].includes(fiscoclic_request.status);
    const wasSent = moment() > moment(fiscoclic_request.sending_time) || wasSentByStatus;
    const data: DisplayData = {
      sentStatusText: wasSent ? "Ticket enviado" : "Ticket no se ha enviado",
      details: [],
    };
    if (wasSent) {
      //** Sent status */
      if ([FiscoClicStatus.REQUESTED_SUCCESSFULLY, FiscoClicStatus.REQUEST_ERROR].includes(fiscoclic_request.status)) {
        data.details.push(sentInfo(sendingDateTime));
      } else {
        data.details.push(sentErrorInfo);
      }
      //** Details */
      data.details.push(folioInfo(fiscoclic_request.folio));
      data.details.push(amountPaidInfo(fiscoclic_request.amount_paid));
      data.details.push(statusInfo(fiscoclic_request.status));
      //** Error message */
      if (fiscoclic_request.status === FiscoClicStatus.REQUEST_ERROR) {
        data.details.push(fiscoClicErrorInfo(fiscoclic_request.message));
      } else if (fiscoclic_request.status === FiscoClicStatus.INTERNAL_ERROR) {
        data.details.push(internalErrorInfo(fiscoclic_request.message));
      } else if (fiscoclic_request.status === FiscoClicStatus.REQUEST_PENDING) {
        data.details.push(paymentPendingInfo);
      }
    } else {
      data.details.push(scheduleInfo(sendingDateTime));
      data.details.push(amountPaidInfo(fiscoclic_request.amount_paid));
      data.details.push(statusInfo(fiscoclic_request.status));
    }
    return data;
  }, [sendingDateTime, appointmentPatient]);

  return (
    <MainWrapper showBorder={!lastElement}>
      <Title>{appointmentPatient.patient.first_name} {appointmentPatient.patient.last_name}</Title>
      <Status>{currentInfo.sentStatusText}</Status>
      {currentInfo.details.map(({ title, text }) => (
        <InfoWrapper key={title}>
          <InfoTitle>{title}:</InfoTitle>
          <InfoText>{text}</InfoText>
        </InfoWrapper>
      ))}
    </MainWrapper>
  );
};

interface BillSectionProps {
  appointmentPatients: AppointmentPatientV2[];
}

//** Facturas para México */
const BillSection = ({ appointmentPatients }: BillSectionProps) => {

  const fiscoClicFilteredPatients = appointmentPatients.filter((patient) => patient.fiscoclic_request);

  return (
    <React.Fragment>
      <ContentTitleWrapper>
        <h3>Facturas</h3>
      </ContentTitleWrapper>
      <MainContainer>
        {fiscoClicFilteredPatients.length ? fiscoClicFilteredPatients.map((patient, index) => (
            // @ts-ignore
            <BillData key={`fiscoclic-${index}`} appointmentPatient={patient} lastElement={index === appointmentPatients.length - 1} />
        )) : (
          <p>Aún no hay información de facturas</p>
        )}
      </MainContainer>
    </React.Fragment>
  );
};

export default BillSection;

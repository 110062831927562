import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";
import labService from "../../../utils/api/v1/labService";
import { CheckoutLabService } from "../../../utils/interfaces/LabServices";

//components
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Flex from "../../Containers/Flex";
import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from "sweetalert2";
import {
  Card,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import NewLabServiceSelector from "./NewLabServiceSelector";
import AddCircleOutlined from "@material-ui/icons/AddCircleOutlined";
import { appointmentPatientAPI } from "../../../utils/api/v2";
import { AppointmentPatientV2 } from "../../../utils/interfaces/AppointmentPatient";

import { addServicePayloadInfo } from "../../../utils/constants/appointmentPatient";

const LoaderWrapper = styled.div`
  padding: 3rem;
`;
interface Props {
  setError: (error: any) => void;
  appointmentPatient: AppointmentPatientV2;
  setAppointmentPatients: Dispatch<SetStateAction<AppointmentPatientV2[]>>;
}

const NewLabService = (props: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [allLabServices, setAllLabServices] = useState<CheckoutLabService[]>(
    []
  );
  const [selectedLabService, setSelectedLabService] =
    useState<CheckoutLabService>();
  const [fonasa, setFonasa] = useState<boolean>(false);

  const fetchLabServices = async () => {
    setLoading(true);
    try {
      const req = await labService.fetchOrderedServices();
      setAllLabServices(req.data.data);
    } catch (err) {
      console.log(err);
      props.setError(err);
      setOpen(false);
    }
    setLoading(false);
  };

  const updateLabServices = async () => {
    setLoading(true);
    try {
      await appointmentPatientAPI.checkSubservices(
        props.appointmentPatient.id,
        selectedLabService!.id
      );
      const response = await appointmentPatientAPI.patch(
        props.appointmentPatient.id,
        {
          services: [{ id: selectedLabService?.id, is_fonasa: fonasa }],
        }
      );
      props.setAppointmentPatients((prev) => {
        const index = prev.indexOf(props.appointmentPatient);
        const newAppointmentPatients = [...prev];
        newAppointmentPatients[index] = response.data;
        return newAppointmentPatients;
      });
      await Swal.fire({
        title: "Actualizado!",
        icon: "success",
      });
    } catch (err) {
      props.setError(err);
      const msg = err.response.data.err;
      const title = addServicePayloadInfo[msg] ?? addServicePayloadInfo.error;
      const text = err.response.data.services ?? "";
      await Swal.fire({
        title: title,
        text: text,
        icon: "error",
      });
    }
    setLoading(false);
    setOpen(false);
  };

  const handleOpen = () => {
    if (allLabServices.length === 0) {
      fetchLabServices();
    }
    setOpen(true);
  };

  useEffect(() => {
    setFonasa(
      props.appointmentPatient.appointment_patient_services.some(
        (s) => s.is_fonasa
      )
    );
  }, [props.appointmentPatient.appointment_patient_services]);

  return (
    <>
      <Card onClick={handleOpen}>
        <List>
          <ListItem>
            <ListItemText primary={"Añadir nuevo servicio"} />
            <IconButton aria-label="delete">
              <AddCircleOutlined />
            </IconButton>
          </ListItem>
        </List>
      </Card>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle style={{ width: "500px" }}>
          Añadir nuevo servicio
        </DialogTitle>
        <DialogContent>
          {loading && (
            <Flex direction="column" align="center">
              <LoaderWrapper>
                <CircularProgress color="primary" />
              </LoaderWrapper>
            </Flex>
          )}
          {!loading && (
            <NewLabServiceSelector
              setSelectedLabService={setSelectedLabService}
              fonasa={fonasa}
              setFonasa={setFonasa}
              allLabServices={allLabServices}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            color="secondary"
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button
            onClick={updateLabServices}
            color="primary"
            disabled={loading}
          >
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NewLabService;

import styled from "styled-components";

export const MainContainer = styled.div`
  margin-block: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const MainWrapper = styled.div`
  background-color: whitesmoke;
  ${(props: { showBorder?: boolean }) => {
    if (props.showBorder) {
      return "padding-bottom: 1.2rem; border-bottom: 1px solid gray;";
    }
  }}
`;

export const Title = styled.h4`
  margin-bottom: 0.3rem;
  min-width: 30%;
  font-size: larger;
`;

export const Status = styled.h6`
  margin-bottom: 1rem;
  padding: 0;
  font-size: medium;
  font-weight: 300;
`;

export const InfoWrapper = styled.div`
  margin-left: 1.5rem;
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

export const InfoTitle = styled.div`
  font-weight: 500 !important;
  width: fit-content;
  flex: 1;
`;

export const InfoText = styled.div`
  font-weight: 200 !important;
  width: fit-content;
  flex: 4;
  text-align: left;
`;
import api from "../api";

class ResultService {
  fetchResults = (appointmentId: string, userId: string, type: string) => {
    return api.get(
      `/appointments/${appointmentId}/retrieve_results/?${type}_id=${userId}`
    );
  };

  uploadResults = (body: any, type: string) => {
    // TODO: another endpoint for upload real_patient results
    return api.post("results/upload-patient-results/", body);
  };
}

const resultService = new ResultService();
export default resultService;

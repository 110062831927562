import React, {
  useMemo,
  useState,
  Fragment,
  Dispatch,
  SetStateAction,
} from "react";
import { Content, ContentTitleWrapper } from "../Styles/Content";
import Flex from "../../../components/Containers/Flex";
import CircularProgress from "@material-ui/core/CircularProgress";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import AddNewPayment from "../../../components/Appointments/AddNewPayment";
import { AppointmentV2, Payment } from "../../../utils/interfaces/Appointment";
import appointmentService from "../../../utils/api/v1/appointmentService";
import Swal from "sweetalert2";
import { numberWithPeriods } from "../../../utils/numbers";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { paymentMethodFormatter } from "../../../utils/payments";
import { AxiosError } from "axios";
import AddNewCoupon from "../AddNewCoupon";

interface PaymentsProps {
  appointmentId: string;
  appointment: AppointmentV2;
  setAppointment: Dispatch<SetStateAction<AppointmentV2 | undefined>>;
  setError: Dispatch<SetStateAction<AxiosError | undefined>>;
  payments: Payment[];
}

type PaymentCardProps = {
  setLoading: Dispatch<SetStateAction<boolean>>;
  appointmentId: string;
  payment: Payment;
  handleRegisterPayment?: () => void;
  updatePaymentsData: (ids: string[]) => void;
  payments: Payment[];
};

const PaymentCard = ({
  setLoading,
  appointmentId,
  payment,
  handleRegisterPayment,
  updatePaymentsData,
  payments,
}: PaymentCardProps) => {
  const handleDeletePayment = async () => {
    const warningText = `Estás eliminando un pago${
      payment.status === "Payment Successful" ? " exitoso" : ""
    } de ${numberWithPeriods(payment.amount, "mx")}`;
    const check = await Swal.fire({
      title: "¿Estás seguro que deseas eliminar este pago?",
      text: warningText,
      icon: "warning",
      showCancelButton: true,
    });
    if (!check.isConfirmed) {
      return;
    }
    try {
      setLoading(true);
      await appointmentService.deletePayment(
        appointmentId,
        payment.id as string
      );
      updatePaymentsData(payments.map((p) => p.id || "").filter((p) => p !== payment.id));
      await Swal.fire({
        title: "Pago eliminado",
        text: `El pago por ${payment.amount} ha sido eliminado con éxito`,
        icon: "success",
      });
    } catch (e) {
      await Swal.fire({
        title: "Error",
        text: `El pago no pudo ser eliminado. ${e.message}`,
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  if (payment.status === "Payment Pending") {
    return (
      <Flex direction="row" align="center">
        <p>Pago pendiente: {numberWithPeriods(payment.amount, "mx")}</p>
        <Button color="primary" onClick={handleRegisterPayment} variant="text">
          Registrar pago
        </Button>
      </Flex>
    );
  }
  return (
    <Flex direction="row" align="center">
      <p>
        ✅ Pago exitoso: {numberWithPeriods(payment.amount, "mx")} -{" "}
        {paymentMethodFormatter(payment)}
      </p>
    </Flex>
  );
};

const Payments = ({
  appointmentId,
  appointment,
  setAppointment,
  setError,
  payments,
}: PaymentsProps) => {
  const [loadingPayment, setLoadingPayment] = useState<boolean>(false);
  const [currentPaymentData, setCurrentPaymentData] = useState<Payment>({
    id: null,
    amount: 0,
    method: "Cash",
    status: "Payment Pending",
    payment_gateway: "In Address",
    gateway_identifier: null,
  });
  const [openPaymentModal, setOpenPaymentModal] = useState<boolean>(false);
  const [pendingPayments, successfulPayments] = useMemo(() => {
    const tempPendingPayments: Payment[] = [];
    const tempSuccessfulPayments: Payment[] = [];
    payments.forEach((payment) => {
      if (payment.status === "Payment Pending") {
        tempPendingPayments.push(payment);
      } else if (payment.status === "Payment Successful") {
        tempSuccessfulPayments.push(payment);
      }
    });
    return [
      tempPendingPayments.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      ),
      tempSuccessfulPayments.sort(
        (a, b) => new Date(b.paid_at) - new Date(a.paid_at)
      ),
    ];
  }, [payments]);
  const handleRegisterPayment = (payment: Payment) => {
    setCurrentPaymentData((currentPaymentData) => ({
      id: payment.id,
      amount: payment.amount,
      method: "In address",
      status: "Payment Pending",
      payment_gateway: "Cash",
    }));
    setOpenPaymentModal(true);
  };

  const updatePaymentsIds = (ids: string[]) => {
    setAppointment((appointment) => {
      if (appointment) {
        return {
          ...appointment,
          payments: ids,
        };
      }
      return undefined;
    });
  };

  return (
    <Fragment>
      <Content>
        <ContentTitleWrapper>
          <h3>Pagos</h3>
        </ContentTitleWrapper>
        {loadingPayment ? (
          <CircularProgress />
        ) : (
          <div>
            <Flex direction="column">
              {pendingPayments.map((payment) => (
                <PaymentCard
                  appointmentId={appointmentId}
                  setLoading={setLoadingPayment}
                  payment={payment}
                  handleRegisterPayment={() => handleRegisterPayment(payment)}
                  updatePaymentsData={updatePaymentsIds}
                  payments={payments}
                />
              ))}
              {pendingPayments.length > 0 && successfulPayments.length > 0 && (
                <hr />
              )}
              {successfulPayments.map((payment) => (
                <PaymentCard
                  appointmentId={appointmentId}
                  setLoading={setLoadingPayment}
                  payment={payment}
                  handleRegisterPayment={() => handleRegisterPayment(payment)}
                  updatePaymentsData={updatePaymentsIds}
                  payments={payments}
                />
              ))}
            </Flex>
            {pendingPayments.length === 0 &&
              successfulPayments.length === 0 && (
                <p>No hay pagos registrados</p>
              )}
          </div>
        )}
      </Content>
    </Fragment>
  );
};

export default Payments;

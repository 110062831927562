import React, { useEffect, useState } from "react";

import {
  Button,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from "@material-ui/core";
import styled from "styled-components";
import Swal from "sweetalert2";
import { AiOutlineFilePdf } from "react-icons/ai";

import resultService from "../../utils/api/v1/resultService";
import { resultAPI } from "../../utils/api/v2";
import { Result } from "../../utils/interfaces/Appointment";
import Flex from "../Containers/Flex";
import { Laboratory } from "../../utils/interfaces/Laboratory";
import labService from "../../utils/api/v1/labService";
import { COUNTRY_TO_CODE } from "../../utils/data/constants";

const ResultWrap = styled.div`
  width: 100%;
  margin-top: 20px;
  display: inline-block;
  background-color: whitesmoke;
  `;

const ResultDisplayWrap = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
  `;
const FileCell = styled.div`
  background-color: white;
  margin-right: 10px;
  padding: 10px;
  border-radius: 5px;
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  `;

const ResultDisplayAndUpload = ({
  appointmentId,
  patientId,
  country,
}: {
  appointmentId: string;
  patientId: string;
  country: string;
  }): JSX.Element => {
  const [existingFiles, setExistingFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [sendNotifs, setSendNotifs] = useState<boolean>(true);
  const [validPhotoUploaded, setValidPhotoUploaded] = useState<boolean>(false);
  const [allAvailableLabs, setAllAvailableLabs] = useState<Laboratory[]>([]);
  const [selectedLab, setSelectedLab] = useState<string>("");

  const fetchingExistingResults = async () => {
    setLoading(true);
    const fetched = await resultAPI.list(appointmentId, patientId);
    setExistingFiles(fetched.data);
    setLoading(false);
  };

  const fetchAllAvailableLabs = async () => {
    setLoading(true);
    try {
      const req = await labService.fetchLabs();
      setAllAvailableLabs(req.data.results);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const handleChange = (event: any) => {
    for (var i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      const filename = file.name;
      const parts = filename.split(".");
      const ext = parts[parts.length - 1];

      if (ext.toLowerCase() !== "pdf") {
        Swal.fire({
          icon: "error",
          title: "El archivo debe ser un PDF",
        });
        return false;
      }

      // if file > 15MB, reject
      if (file.size > 15_000_000) {
        Swal.fire({
          icon: "error",
          title: "El archivo no debe pesar mas de 15MB",
        });
        setValidPhotoUploaded(false);
        return false;
      }
      // add file to files state
      setValidPhotoUploaded(true);
      setFiles((prevFiles) => {
        return [...prevFiles, file];
      });
    }
  };

  const uploadResultConfirmation = () => {
    Swal.fire({
      icon: "warning",
      title: "¿Quieres subir los resultados?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.value) {
        setUploading(true);

        const formData = new FormData();
        formData.append("appointment_id", appointmentId);
        formData.append("user_id", patientId);
        files.forEach((file) => {
          formData.append("files", file);
        });
        formData.append("automatic", false);
        formData.append("send_notifs", sendNotifs);
        formData.append("type", "patient");
        formData.append("lab_id", selectedLab);

        await resultService.uploadResults(formData, "patient");

        Swal.fire({
          icon: "success",
          title: "Resultados subidos",
        });

        fetchingExistingResults();
        // cleanup
        setFiles([]);
        setUploading(false);
      }
    });
  };

  useEffect(() => {
    fetchingExistingResults();
    fetchAllAvailableLabs();
  }, []);

  return (
    <ResultWrap>
      <h3>Resultados disponibles</h3>
      <ResultDisplayWrap>
        {loading ? (
          <p>Cargando resultados...</p>
        ) : existingFiles.length === 0 ? (
          <p>No hay resultados disponibles</p>
        ) : (
          <Flex wrap="wrap" gap="1rem">
            {existingFiles.map((existingFile: Result, i) => (
              <Flex direction="column">
                <a target="_blank" href={existingFile.file_link} key={i}>
                  <FileCell>
                    <AiOutlineFilePdf size={38} />
                    <p>Resultado {i + 1}</p>
                  </FileCell>
                </a>
                {existingFile.lab_name && (
                  <p style={{ margin: "0", textOverflow: "ellipsis", textAlign: "center", width: "100%" }}>
                    {existingFile.lab_name}
                  </p>
                )}
              </Flex>
            ))}
          </Flex>
        )}
      </ResultDisplayWrap>
    </ResultWrap>
  );
};

export default ResultDisplayAndUpload;

import styled from "styled-components";

export const UploadItem = styled.div`
	display: inline-flex;
	font-size: 16px;
`;

export const ImgContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
`;

export const FileCell = styled.div`
	background-color: white;
	margin-right: 10px;
	padding: 10px;
	border-radius: 5px;
	display: inline-flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
`;

import React, {useState} from "react";
import packService from "../../utils/api/v1/packService";

import Button from "@material-ui/core/Button";
import Flex from "../Containers/Flex";
import {
  Card,
  IconButton,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";
import AddCircleOutlined from "@material-ui/icons/AddCircleOutlined";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import {LoaderWrapper} from "../Appointments/Styles/Content";
import TextField from "@material-ui/core/TextField";
import {SAutocomplete} from "../../styles/Layout/Selectors";
import {appointmentPatientAPI} from "../../utils/api/v2";
import {AppointmentPatientV2} from "../../utils/interfaces/AppointmentPatient";

interface Props {
  appointmentId: string;
  appointmentPatient: AppointmentPatientV2;
  setError: (error: any) => void;
  fonasa: boolean;
  reloadAppointmentPatient: () => void;
}

const AddPack = (props: Props): JSX.Element => {
  const [modal, setModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectPack, setSelectPack] = useState<object>({});
  const [allPacks, setAllPacks] = useState<object[]>([]);
  const handleOpen = () => {
    setLoading(true);
    setModal(true);
    fetchAllPacks().then(() => {
      setLoading(false);
    });
  };
  const setAllPacksFiltered = (rawAllPacks) => {
    if (props.fonasa){
      setAllPacks(rawAllPacks.filter((pack) => {
        return pack.fonasa
      }));
    }
    setAllPacks(rawAllPacks);
  };
  const fetchAllPacks = async () => {
    setLoading(true);
    try {
      const req = await packService.fetchPacks(props.appointmentId);
      setAllPacksFiltered(req.data);
    } catch (err) {
      setModal(false);
      props.setError(err);
    }
    setLoading(false);
  };

  return (
    <>
      <Card onClick={handleOpen}>
        <List>
          <ListItem>
            <ListItemText primary={"Añadir packs"} />
            <IconButton aria-label="delete">
              <AddCircleOutlined />
            </IconButton>
          </ListItem>
        </List>
      </Card>
      <Dialog
        open={modal}
        onClose={() => {
          setModal(false);
        }}
      >
        <DialogTitle style={{ width: "500px" }}>
          Añadir nuevo Pack
        </DialogTitle>
        <DialogContent>
          {loading && (
            <Flex direction="column" align="center">
              <LoaderWrapper>
                <CircularProgress color="primary" />
              </LoaderWrapper>
            </Flex>
          )}
          {!loading && (
              <SAutocomplete
          id="combo-box-demo"
          options={allPacks}
          defaultValue={"Selecciona un pack"}
          getOptionLabel={(option: any) => {
            return option.name
              ? `${option.name} - $${option.final_price}`
              : "Selecciona un pack";
          }}
          value={selectPack}
          onChange={(event: any, newValue: any) => {
            setSelectPack(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} helperText="Pack" variant="standard" />
          )}
        />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setModal(false);
            }}
            color="secondary"
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button
            onClick={async () => {
              await appointmentPatientAPI.addPack({
                pack: selectPack["id"],
                appointment_patient: props.appointmentPatient.id

              });
              props.reloadAppointmentPatient();
              setModal(false);
            }}
            color="primary"
            disabled={loading}
          >
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddPack;
